<template>
  <v-container fluid>
    <div v-if="Department && Department._id">
      <div class="container">
        <h2>
          Mention :
          <span class="primary--text">
            {{ Department.name }}
          </span>
        </h2>
      </div>

      <div class="my-5">
        <v-data-table
          class="pt-3"
          flat
          :loading="loading ? '#144881' : null"
          :headers="headersOptions"
          :search="searchOptions"
          :items="Options"
          :items-per-page="5"
          sort-by=""
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Option</v-toolbar-title>

              <v-spacer></v-spacer>
              <template>
                <v-btn
                  color="#144881"
                  dark
                  class="mb-2 mr-8"
                  @click="addOption"
                  v-bind="attrs"
                  outlined
                  pill
                  v-on="on"
                  rounded
                >
                  <v-icon left> mdi-account-outline </v-icon>
                  Ajout d'une option
                </v-btn>
              </template>
            </v-toolbar>
            <v-container fluid>
              <v-expansion-panels flat style="border: 1px solid #144881">
                <v-expansion-panel class="elevation-0 pa-0">
                  <v-expansion-panel-header>
                    Tri / Recherche ...
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col cols="12" md="12">
                      <v-text-field
                        color="#144881"
                        label="Recherche "
                        class="pt-5 pa-2"
                        filled
                        prepend-inner-icon="mdi-magnify"
                        dense
                        v-model="searchOptions"
                        outlined
                        flat
                        background-color="grey lighten-4"
                        rounded
                      ></v-text-field>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu elevation="0" right offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
                  <v-icon color="success" size="30">mdi-forwardburger</v-icon>
                </v-btn>
              </template>
              <v-list>
              
                <v-tooltip bottom color="warning">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-3" icon>
                      <v-icon
                        size="20"
                        @click="updateOption(item)"
                        v-bind="attrs"
                        v-on="on"
                        color="warning"
                        >mdi-pencil-box-multiple-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Modifier</span>
                </v-tooltip>
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-3" icon>
                      <v-icon
                        size="20"
                        @click="deleteOption(item)"
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>

      <div class="my-5">
        <v-data-table
          class="pt-3"
          flat
          :loading="loading ? '#144881' : null"
          :headers="headers"
          :search="searchData"
          :items="StandardFees"
          :items-per-page="5"
          sort-by=""
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Frais standard </v-toolbar-title>

              <v-spacer></v-spacer>
              <template>
                <v-btn
                  color="#144881"
                  dark
                  class="mb-2 mr-8"
                  @click="addItem"
                  v-bind="attrs"
                  outlined
                  pill
                  v-on="on"
                  rounded
                >
                  <v-icon left> mdi-account-outline </v-icon>
                  Ajout d'un frais standard
                </v-btn>
              </template>
            </v-toolbar>
            <v-container fluid>
              <v-expansion-panels flat style="border: 1px solid #144881">
                <v-expansion-panel class="elevation-0 pa-0">
                  <v-expansion-panel-header>
                    Tri / Recherche ...
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col cols="12" md="12">
                      <v-text-field
                        color="#144881"
                        label="Recherche "
                        class="pt-5 pa-2"
                        filled
                        prepend-inner-icon="mdi-magnify"
                        dense
                        v-model="searchData"
                        outlined
                        flat
                        background-color="grey lighten-4"
                        rounded
                      ></v-text-field>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu elevation="0" right offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
                  <v-icon color="success" size="30">mdi-forwardburger</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-tooltip bottom color="warning">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-3" icon>
                      <v-icon
                        size="20"
                        @click="updateItem(item)"
                        v-bind="attrs"
                        v-on="on"
                        color="warning"
                        >mdi-pencil-box-multiple-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Modifier</span>
                </v-tooltip>
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-3" icon>
                      <v-icon
                        size="20"
                        @click="deleteItem(item)"
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>

      <div class="my-5">
        <v-data-table
          class="pt-3"
          flat
          :loading="loading ? '#144881' : null"
          :headers="headersAdditional"
          :search="searchAdditionalCoast"
          :items="AdditionalCoasts"
          :items-per-page="5"
          sort-by=""
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Frais additionel </v-toolbar-title>

              <v-spacer></v-spacer>
              <template>
                <v-btn
                  color="#144881"
                  dark
                  class="mb-2 mr-8"
                  @click="addAdditionalCoast"
                  v-bind="attrs"
                  outlined
                  pill
                  v-on="on"
                  rounded
                >
                  <v-icon left> mdi-account-outline </v-icon>
                  Ajout d'un frais additionel
                </v-btn>
              </template>
            </v-toolbar>
            <v-container fluid>
              <v-expansion-panels flat style="border: 1px solid #144881">
                <v-expansion-panel class="elevation-0 pa-0">
                  <v-expansion-panel-header>
                    Tri / Recherche ...
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col cols="12" md="12">
                      <v-text-field
                        color="#144881"
                        label="Recherche "
                        class="pt-5 pa-2"
                        filled
                        prepend-inner-icon="mdi-magnify"
                        dense
                        v-model="searchAdditionalCoast"
                        outlined
                        flat
                        background-color="grey lighten-4"
                        rounded
                      ></v-text-field>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu elevation="0" right offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
                  <v-icon color="success" size="30">mdi-forwardburger</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-tooltip bottom color="warning">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-3" icon>
                      <v-icon
                        size="20"
                        @click="updateAdditionalCoast(item)"
                        v-bind="attrs"
                        v-on="on"
                        color="warning"
                        >mdi-pencil-box-multiple-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Modifier</span>
                </v-tooltip>
                <v-tooltip bottom color="red">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-3" icon>
                      <v-icon
                        size="20"
                        @click="deleteAdditionalCoast(item)"
                        v-bind="attrs"
                        v-on="on"
                        color="red"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog v-model="editorDialog" max-width="1000px" class="black">
      <editor-x
        v-if="editorDialog"
        :fields="fields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
        :title="
          Model && Model._id
            ? `Modification d'un frais standard`
            : `Ajout d'un frais standard`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>

    <!-- additional -->
    <v-dialog v-model="additionalEditorDialog" max-width="1000px" class="black">
      <editor-x
        v-if="additionalEditorDialog"
        :fields="additionalFields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
        :title="
          Model && Model._id
            ? `Modification d'un frais additionel`
            : `Ajout d'un frais additionel`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
      />
    </v-dialog>
    <v-dialog v-model="additionalDeleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>

    <!-- option -->
    <v-dialog v-model="editorOptionDialog" max-width="1000px" class="black">
      <editor-x
        v-if="editorOptionDialog"
        :fields="optionFields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
        :title="
          Model && Model._id
            ? `Modification d'une option`
            : `Ajout d'une option`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
      />
    </v-dialog>
    <v-dialog v-model="deleteOptionDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "@/utils/data";
import {
  addStandardFees,
  updateStandardFees,
  deleteStandardFees,
  addAdditionalCoast,
  updateAdditionalCoast,
  deleteAdditionalCoast,
  addOption,
  updateOption,
  deleteOption,
} from "@/api/user";
import EditorX from "@/components/universal/EditorX.vue";
import DeleteDialog from "@/components/universal/DeleteDialog.vue";
import { getLevels, getStandardFees } from "@/api/universal";
import { rules } from "../../../utils/constant";

export default {
  name: "Department",
  components: {
    EditorX,
    DeleteDialog,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    searchOptions: "",
    searchAdditionalCoast: "",
    dialog: false,
    additionalDeleteDialog: false,
    loading: false,
    headers: [
      
    {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
  sortable: true,
      },
      {
        text: "Nom",
        align: "start",
  sortable: true,
        value: "name",
      },
      {
        text: "Montant",
  sortable: true,
        value: "amount",
      },
      {
        text: "Description",
  sortable: true,
        value: "description",
      },
      {
        text: "Dégré",
        sortable: true,
        value: "level",
      },
      {
        text: "Option",
        sortable: true,
        value: "optionName",
      },
      {
        text: "Ordre de payement",
        sortable: true,
        value: "order",
      },

    ],
    headersAdditional: [
      
    {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
  sortable: true,
      },
      {
        text: "Nom",
        align: "start",
  sortable: true,
        value: "name",
      },
      {
        text: "Montant",
  sortable: true,
        value: "amount",
      },
      {
        text: "Description",
  sortable: true,
        value: "description",
      },
      {
        text: "Dégré",
        sortable: true,
        value: "level",
      },
      {
        text: "Option",
        sortable: true,
        value: "optionName",
      },

    ],
    headersOptions: [
    {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
  sortable: true,
      },
      {
        text: "Nom",
        align: "start",
  sortable: true,
        value: "name",
      },
      {
        text: "Acronyme",
  sortable: true,
        value: "acronym",
      },
      {
        text: "Description",
  sortable: true,
        value: "description",
      },
      {
        text: "Code",
  sortable: true,
        value: "code",
      },
     
    ],
    editedIndex: -1,
    Model: {},
    editorDialog: false,
    deleteDialog: false,
    additionalEditorDialog: false,
    aditionalDeleteDialog: false,
    editorOptionDialog: false,
    deleteOptionDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "StandardFees",
      "Department",
      "AdditionalCoasts",
      "Options",
    ]),

    fields() {
      console.log(getStandardFees())
      console.log(rules.diff(this.StandardFees.map((item) => item.name)))
      return [
        {
          name: "name",
          label: "Nom",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            objectData: getStandardFees(),
            // unWantedData: this.StandardFees.map((item) => item.name),
            unWantedData:[],
            fieldName: "name",
            fieldValue: "value",
            allowOtherValue: true,
            otherRules: [
              // rules.diff(this.StandardFees.map((item) => item.name)),
            ],
          },
        },
        {
          name: "amount",
          label: "Montant",
          type: "Number",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "level",
          label: "Dégré",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: getLevels(),
          },
        },
        {
          name: "description",
          label: "Descripiton",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "order",
          label: "Ordre de payement",
          type: "Number",
          col: 12,
          // rules: [rules.diff(this.StandardFees.map((item) => item.order))],
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        // {
        //   name: "department",
        //   label: "",
        //   type: "Static",
        //   value: this.$route.params.id,
        //   isRequiredForUpdate: true,
        //   isRequiredForAdd: true,
        //   existForUpdate: true,
        //   existForAdd: true,
        // },
        {
          name: "option",
          label: "Option",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getOptions,
              params: { department: this.$route.params.id },
              getter: this.Options,
              fieldName: "name",
            },
          },
        },
      ];
    },
    additionalFields() {
      return [
        {
          name: "name",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "amount",
          label: "Montant",
          type: "Number",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "level",
          label: "Dégré",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: getLevels(),
          },
        },

        {
          name: "description",
          label: "Descripiton",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },

        // {
        //   name: "department",
        //   label: "",
        //   type: "Static",
        //   value: this.$route.params.id,
        //   isRequiredForUpdate: true,
        //   isRequiredForAdd: true,
        //   existForUpdate: true,
        //   existForAdd: true,
        // },
        {
          name: "option",
          label: "Option",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getOptions,
              params: { department: this.$route.params.id },
              getter: this.Options,
              fieldName: "name",
            },
          },
        },
      ];
    },
    optionFields() {
      return [
        {
          name: "name",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "acronym",
          label: "Acronyme",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "description",
          label: "Descripiton",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "code",
          label: "Code de l'option",
          icon: "mdi-key",
          type: "String",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "department",
          label: "",
          type: "Static",
          value: this.$route.params.id,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions(["getDepartment", "getOptions"]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getDepartment({ department: this.$route.params.id });
        await this.getOptions({ department: this.$route.params.id });
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("le département")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.additionalEditorDialog = false;
      this.aditionalDeleteDialog = false;
      this.editorOptionDialog = false;
      this.deleteOptionDialog = false;

      this.init();
    },
    addOption() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addOption;
      this.editorOptionDialog = true;
    },
    updateOption(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorOptionDialog = true;
      this.callBack = updateOption;
    },
    deleteOption(item) {
      this.deleteOptionDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteOption;
    },

    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addStandardFees;
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateStandardFees;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteStandardFees;
    },
    addAdditionalCoast() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addAdditionalCoast;
      this.additionalEditorDialog = true;
    },
    updateAdditionalCoast(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.additionalEditorDialog = true;
      this.callBack = updateAdditionalCoast;
    },

    deleteAdditionalCoast(item) {
      this.aditionalDeleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteAdditionalCoast;
    },
  },
};
</script>

<style >
</style>
